.vote-contestant-dialog-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  // backdrop-filter: blur(4px);
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  box-sizing: border-box;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;
  background: #000000b0;
}

.vote-contestant-dialog-wrapper.enable {
  opacity: 1;
  pointer-events: all;
}

.vote-contestant-dialog-container {
  width: 100%;
  max-width: 960px;
  height: 100%;
  // max-height: 80vh;
  display: grid;
  align-items: center;
  gap: 16px;
  grid-template-columns: 36px 1fr 36px;
}

.vote-contestant-dialog-container * {
  transition: all 0.25s ease-in-out;
}

.vote-contestant-dialog-container .arrow {
  height: 72px;
  width: 72px;
  transition: all 0.25s ease-in-out;
}

.vote-contestant-dialog-container .arrow.hide {
  opacity: 0;
  pointer-events: none;
}

.vote-contestant-dialog-container .left {
  cursor: pointer;
}

.vote-contestant-dialog-container .right {
  cursor: pointer;
}

.vote-contestant-dialog-container .left:hover path {
  animation: bounce-left-right 1s linear infinite reverse;
}

.vote-contestant-dialog-container .right:hover path {
  animation: bounce-left-right 1s linear infinite;
}

.vote-contestant-dialog-container .main .backdrop-edge {
  background: linear-gradient(167deg, #dcac60, #9e7c45);
  border-radius: 20px;
  padding: 2px;
}

.vote-contestant-dialog-container .main .backdrop-edge .backdrop {
  background: white;
  border-radius: 20px;
  // padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-height: 90vh;
  // max-width: 90vw;
  // padding: 24px;
  padding: 16px;
}

.vote-contestant-dialog-container .main .backdrop-edge .backdrop .top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.6rem;
  box-sizing: border-box;
}

.vote-contestant-dialog-container
  .main
  .backdrop-edge
  .backdrop
  .top-bar
  .close-btn {
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 2;
}

.vote-contestant-dialog-container
  .main
  .backdrop-edge
  .backdrop
  .top-bar
  .close-btn
  svg {
  width: 100%;
  height: 100%;
}

.vote-contestant-dialog-container
  .main
  .backdrop-edge
  .backdrop
  .top-bar
  .close-btn
  svg
  * {
  transition: all 0.2s ease-in-out;
}

.vote-contestant-dialog-container
  .main
  .backdrop-edge
  .backdrop
  .top-bar
  .close-btn:hover
  svg
  circle {
  fill: #dcac60;
}

.vote-contestant-dialog-container
  .main
  .backdrop-edge
  .backdrop
  .top-bar
  .close-btn:hover
  svg
  path {
  stroke: #f8f6f4;
}

.vote-contestant-dialog-title-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.vote-contestant-dialog-title {
  color: #1a1a1a;
  text-align: center;
  font-family: Noto Serif TC;
  // font-size: 60px;
  font-size: 48px;

  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3px;
  border-radius: 5px;
  background: linear-gradient(
    65deg,
    #e9c156,
    #d6ab4a 14%,
    #e1ab39 31%,
    #e3b040 45%,
    #edcc66 53%,
    #f4e283 60%,
    #faf299 68%,
    #fdfba5 75%,
    #ffa 82%,
    #fdf9a3 85%,
    #f8eb8f 90%,
    #efd470 96%,
    #e9c156
  );
  width: fit-content;
  padding: 0.05em 0.25em;
  transform: translateY(-50%);
  white-space: nowrap;

  &::before {
    background: linear-gradient(
        270deg,
        #ae873d -1.34%,
        #fafab9 18.63%,
        #fafab9 48.59%,
        #fafab9 78.54%,
        #ae873d 98.52%
      ),
      linear-gradient(136.74deg, #dcac60 3.39%, #9e7c45 113.44%);
    background-position: 50%;
    background-size: 100%;
    border-radius: 4px;
    content: "";
    height: calc(100% - 2px);
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
    transition: all 0.3s ease;
    width: calc(100% - 2px);
    z-index: 1;
    z-index: -1;
  }
}

.contestant-vote-btn {
  // filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.125));
  margin-top: 16px;
  border: 2px solid transparent;
  border-radius: 100px;
  background: linear-gradient(
    65deg,
    #e9c156 0%,
    #d6ab4a 14%,
    #e1ab39 31%,
    #e3b040 45%,
    #edcc66 53%,
    #f4e283 60%,
    #faf299 68%,
    #fdfba5 75%,
    #ffffaa 82%,
    #fdf9a3 85%,
    #f8eb8f 90%,
    #efd470 96%,
    #e9c156 100%
  );
  background-size: 200% 200%;
  animation: contestant-vote-btn-shine 5s ease-in-out infinite;
  color: #fff;
  text-align: center;
  font-family: Noto Serif TC;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  letter-spacing: 3.3px;
  padding: 4px 28px;
  align-self: center;
  cursor: pointer;
  background-position: 0% 0%;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    transform: translate(1px, 1px);
    background: linear-gradient(136.74deg, #dcac60 3.39%, #9e7c45 113.44%);
    border-radius: 100px;
    z-index: -1;
  }

  @media screen and (max-width: 848px) {
    margin-top: 0px;
  }
}

.contestant-vote-btn:hover {
  transition: all 0.15s ease-in-out;
  border: 2px solid #dcac60;
}

@keyframes contestant-vote-btn-shine {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 90% 90%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.contestant-vote-btn:hover {
  transition: all 0.15s ease-in-out;
  border: 2px solid #dcac60;
}

.vote-contestant-dialog-title p {
  margin: unset;
}

.backdrop .vote-wrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  // padding-left: 36px;
  // padding-right: 36px;
}

.backdrop .vote-container {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 50%;
  box-sizing: border-box;
  max-height: 75vh;
  width: 100%;
  // overflow-y: scroll;
  @media screen and (max-width: 768px) {
    overflow-y: unset;
  }
}
.backdrop .vote-container .profile-picture-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: inherit;
  overflow: hidden;
  padding: 0em;
  // height: fit-content;

  @media screen and (max-width: 512px) {
    padding: 2em 0em 1em 0em;
  }
}

.backdrop .vote-container .profile-picture-container {
  // aspect-ratio: 1;
  position: relative;
  background: linear-gradient(
    35deg,
    #e9c156,
    #d6ab4a 14%,
    #e1ab39 31%,
    #e3b040 45%,
    #edcc66 53%,
    #f4e283 60%,
    #faf299 68%,
    #fdfba5 75%,
    #ffa 82%,
    #fdf9a3 85%,
    #f8eb8f 90%,
    #efd470 96%,
    #e9c156
  );
  border-radius: 22px;
  // overflow: hidden;
  padding: 2px;
  // width: 400px;
  width: 100%;
  max-width: 300px;
}

.backdrop .vote-container .profile-picture-container .contestant_id {
  color: #b59473;
  font-family: Helvetica Neue, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3px;
  -webkit-text-stroke: 1px white;
  position: absolute;
  top: 0;
  right: 16px;
  transform: translateY(-50%);
  margin: unset;
}

.backdrop .vote-zone-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-y: auto;
}

.backdrop .vote-zone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0em 1em;

  // @media screen and (max-width: 430px) {
  // }
}

.backdrop .vote-zone #last {
  // height: -webkit-fill-available;
  height: max-content;
  display: flex;
  gap: 1em;
}

.backdrop .vote-zone #last .vote-btn {
  align-self: flex-end;
  transition: all 0.2s ease-in-out;
}

.vote-btn.secondary {
  background: #fff;
  color: #dcac60;
  border: 1px solid #dcac60;
}

.vote-btn.secondary:hover {
  background: #dcac60;
  color: #fff;
}

.backdrop .vote-zone #last .vote-btn.disabled {
  filter: grayscale(1);
  opacity: 0.5;
  pointer-events: none;
}

.backdrop .vote-container .vote-chant {
  color: #1a1a1a;
  font-family: Noto Serif TC;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.9px;
  padding-bottom: 0.4em;
  border-bottom: 1px solid #dcac60;
  height: fit-content;
  margin-top: 0;
}

.backdrop .vote-container .vote-chant.confirmation {
  border-bottom: unset;
}

.backdrop .vote-container .vote-chant.confirmation .notes {
  font-size: 16px;
  opacity: 0.5;
}

.backdrop .vote-container .vote-chant .name_cn {
  color: #dcac60;
  font-family: Noto Sans TC, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 4.5px;
}

.backdrop .vote-container .vote-chant .name_en {
  color: #dcac60;
  font-family: Noto Sans TC, sans-serif;
  font-size: 28px;
  letter-spacing: 1.4px;
}

.backdrop .vote-container .vote-chant .name_en.small {
  font-size: 24px;
}

.profile-picture-container.prev {
  animation: swipe-from-right 0.25s ease-in-out;
}

.profile-picture-container.next {
  animation: swipe-from-left 0.25s ease-in-out;
}

.vote-btn-list {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.vote-btn-list .fix {
  aspect-ratio: 1;
}

.vote-btn-list input {
  height: 48px;
  width: fit-content;
  max-width: 128px;
  border: unset;
  color: #dcac60;
  text-align: center;
  font-family: Times New Roman;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 3.8px;
  text-indent: 3.8px;
  border-radius: 5px;
  background: linear-gradient(0deg, #f8f6f4 0%, #f8f6f4 100%), #efefef;
  margin: 0 8px;
}

.vote-btn-list button {
  border-radius: 80px;
  border: 1px solid #dcac60;
  background: #fff;
  color: #dcac60;
  text-align: center;
  font-family: Times New Roman;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2.6px;
  padding: 0 12px;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  cursor: pointer;
}

.vote-btn-list button:hover {
  background: #dcac60;
  color: #fff;
}

.vote-btn-list button:hover path {
  fill: white;
}

.vote-zone .notes {
  text-align: left;
  margin-top: 12px;
  align-self: flex-start;
}

.vote-zone .notes p {
  font-family: Noto Sans TC, sans-serif;
  margin: 4px 0;
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
}

.vote-zone .notes .insufficient {
  color: #f0423b;
  font-family: Noto Sans TC, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1.2px;
  transition: all 0.2s ease-in-out;
}

.vote-zone .notes .insufficient.disabled {
  opacity: 0;
  pointer-events: none;
}

.vote-zone .notes .insufficient .highlight {
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.vote-zone .notes .insufficient .highlight a {
  color: unset;
}

.vote-zone .notes .insufficient .highlight:hover {
  opacity: 0.6;
}

.voting-button-list {
  // display: flex;
  // flex-direction: column;
  // gap: 0.6rem;
  // justify-content: center;
  // align-items: center;
  // margin: 1rem 0rem;

  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  gap: 0.4rem;
  justify-content: center;
  padding-top: 1em;

  @media screen and (max-width: 848px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (max-width: 430px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.voting-details-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 1.2rem;
  margin-bottom: 2rem;
  width: 100%;

  @media screen and (max-width: 848px) {
    .input-sizing {
      font-size: 18px;
    }
  }
}

@keyframes bounce-left-right {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(10%);
  }

  50% {
    transform: translateX(0%);
  }

  75% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(0%);
  }
}

@media only screen and (max-width: 1440px) {
  .backdrop .vote-container {
    overflow: hidden;
  }
}

@media only screen and (max-width: 1024px) {
  .vote-contestant-dialog-title {
    font-size: 32px;
  }

  .vote-contestant-dialog-container {
    grid-template-columns: 16px 1fr 16px;
    gap: 8px;
  }

  .vote-contestant-dialog-container .arrow {
    height: 64px;
    width: 64px;
  }

  .vote-contestant-dialog-container
    .main
    .backdrop-edge
    .backdrop
    .top-bar
    .close-btn {
    width: 36px;
    height: 36px;
  }
  .vote-contestant-dialog-container .main .backdrop-edge .backdrop {
    padding: 12px;
  }
  .backdrop .vote-container .profile-picture-container {
    aspect-ratio: unset;
  }

  .backdrop .vote-container {
    gap: 24px;
    // padding-left: 24px;
    // padding-right: 24px;
    // padding-bottom: 36px;
    // max-height: 50vh;
    // grid-template-columns: 2fr 3fr;
  }

  .backdrop .vote-container .vote-chant {
    font-size: 20px;
  }

  .backdrop .vote-container .vote-chant.confirmation .notes {
    font-size: 14px;
  }

  .backdrop .vote-container .vote-chant .name_cn {
    font-size: 24px;
  }

  .backdrop .vote-container .vote-chant .name_en {
    font-size: 22px;
  }

  .backdrop .vote-container .vote-chant .name_en.small {
    font-size: 18px;
  }

  .vote-btn-list button {
    height: 48px;
    font-size: 20px;
    letter-spacing: 1px;
  }

  .vote-btn-list input {
    max-width: 96px;
    font-size: 28px;
    height: 32px;
  }

  .vote-zone .notes p {
    font-size: 16px;
  }

  .vote-zone .notes .insufficient {
    font-size: 18px;
  }
}

@media only screen and (max-width: 848px) {
  // .backdrop .vote-container {
  //   overflow-y: scroll;
  // }
  .vote-contestant-dialog-container {
    // grid-template-columns: 48px 1fr 48px;
    gap: 6px;
  }

  .vote-contestant-dialog-container .arrow {
    height: 48px;
    width: 48px;
  }

  .backdrop .vote-container .profile-picture-container {
    aspect-ratio: 0.75;
  }

  .backdrop .vote-container .vote-chant {
    font-size: 14px;
    padding-bottom: 8px;
  }

  .backdrop .vote-container .vote-chant.confirmation .notes {
    font-size: 12px;
  }

  .backdrop .vote-container .vote-chant .name_cn {
    font-size: 18px;
  }

  .backdrop .vote-container .vote-chant .name_en {
    font-size: 16px;
  }

  .backdrop .vote-container .vote-chant .name_en.small {
    font-size: 14px;
  }

  .vote-btn-list button {
    height: 32px;
    padding: 0 8px;
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  .vote-btn-list input {
    font-size: 16px;
    max-width: 81px;
    height: 32px;
    margin: unset;
  }

  .vote-zone .notes p {
    font-size: 14px;
  }

  .vote-zone .notes .insufficient {
    font-size: 16px;
  }
}

@media only screen and (max-width: 720px) {
  .backdrop .profile-picture-wrapper {
    padding: 2.5em 0em 1em 0em;
  }
  .backdrop .vote-zone-container {
    align-items: flex-start;
    overflow-y: scroll;
    height: 340px;
  }

  .vote-contestant-dialog-container {
    width: 100%;
    grid-template-columns: 16px 1fr 16px;
    gap: 4px;
  }

  .backdrop .vote-container .profile-picture-container {
    aspect-ratio: 1;
    width: 40vw;
  }

  .backdrop .vote-zone #last {
    margin-bottom: 16px;
  }

  .backdrop .vote-container {
    display: flex;
    gap: 16px;
    flex-direction: column;
    max-height: fit-content;
    justify-content: center;
    align-items: center;
  }

  .vote-btn-list button {
    padding: 8px 8px;
    letter-spacing: 0;
    height: 32px;
  }

  .vote-btn-list input {
    height: 32px;
  }

  .vote-contestant-dialog-container .main {
    // max-width: calc(100vw - 16px);
    // max-height: calc(90vh);
  }

  .vote-contestant-dialog-container .arrow {
    height: 36px;
    width: 36px;
  }

  // .vote-contestant-dialog-container .left {
  //     transform: translateX(100%);
  //     z-index: 2;
  // }

  // .vote-contestant-dialog-container .left circle {
  //     stroke: white;
  //     stroke-width: 2;
  // }

  // .vote-contestant-dialog-container .right {
  //     transform: translateX(-100%);
  //     z-index: 2;
  // }

  // .vote-contestant-dialog-container .right circle {
  //     stroke: white;
  //     stroke-width: 2;
  // }

  .vote-btn {
    font-size: 16px;
  }

  .backdrop .vote-container .vote-chant .name_cn {
    letter-spacing: 2px;
  }

  .backdrop .vote-container .vote-chant .name_en {
    letter-spacing: 0px;
  }
}

@media screen and (max-width: 512px) {
  .backdrop .vote-container {
    overflow-y: hidden;
  }
}

@keyframes swipe-from-left {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes swipe-from-right {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.test {
  background-color: red !important;
}
