.success-dialog-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(4px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  box-sizing: border-box;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;
  background: #000000b0;
}

.success-dialog-wrapper.enable {
  opacity: 1;
  pointer-events: all;
}

.success-dialog-thanks-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: flex-start;
}

.success-dialog-thanks-wrapper .vote {
  color: #333;
  font-family: Noto Sans TC, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  letter-spacing: 0px;
}

.success-dialog-thanks {
  color: #1a1a1a;
  font-family: Noto Serif TC;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.2px;
  // margin-bottom: 24px;
}

.success-dialog-name-cn {
  color: #dcac60;
  font-family: Noto Sans TC;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 4.5px;
}

.success-dialog-name-en {
  color: #dcac60;
  font-family: Noto Sans TC;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.4px;
}

.success-dialog-container {
  width: fit-content;
  max-width: 1120px;
  align-items: center;
  gap: 16px;
  display: flex;
  justify-content: center;
}

.success-dialog-container * {
  transition: all 0.25s ease-in-out;
}

.success-dialog-container .main .backdrop-edge {
  background: linear-gradient(167deg, #dcac60 0%, #8e3798 50%, #358fe1 100%);
  border-radius: 20px;
  padding: 2px;
  position: relative;
}

.success-dialog-container .main .backdrop-edge .backdrop #title {
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.success-dialog-container .main .backdrop-edge .backdrop #content {
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 256px;
  width: 100%;
  display: flex;
  gap: 32px;
}

.success-dialog-container .main .backdrop-edge .backdrop #title img {
  padding: 12px 24px;
  box-sizing: border-box;
  width: 100%;
  object-fit: contain;
}

.success-dialog-container .main .backdrop-edge .backdrop #title #label {
  position: absolute;
  bottom: 0;
  color: #dcac60;
  text-align: center;
  font-family: Noto Serif TC;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 12px;
  border-bottom: 2px solid #dcac60;
  width: fit-content;
}

.success-dialog-container .main .backdrop-edge .backdrop {
  background: white;
  border-radius: 20px;
  // padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;
  max-height: 90vh;
  max-width: 90vw;
  width: 100%;
}

.success-dialog-container .main .backdrop-edge .backdrop .top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  position: absolute;
}

.success-dialog-container .main .backdrop-edge .backdrop .top-bar .close-btn {
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 2;
}

.success-dialog-container
  .main
  .backdrop-edge
  .backdrop
  .top-bar
  .close-btn
  svg {
  width: 100%;
  height: 100%;
}

.success-dialog-container
  .main
  .backdrop-edge
  .backdrop
  .top-bar
  .close-btn
  svg
  * {
  transition: all 0.2s ease-in-out;
}

.success-dialog-container
  .main
  .backdrop-edge
  .backdrop
  .top-bar
  .close-btn:hover
  svg
  circle {
  fill: #dcac60;
}

.success-dialog-container
  .main
  .backdrop-edge
  .backdrop
  .top-bar
  .close-btn:hover
  svg
  path {
  stroke: #f8f6f4;
}

.success-dialog-title-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.success-dialog-title {
  color: #fff;
  text-align: center;
  font-family: Noto Serif TC;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3px;
  border-radius: 5px;
  background: linear-gradient(156deg, #d777b5 0%, #c883db 49.36%, #41b0d8 100%),
    #efefef;
  width: fit-content;
  padding: 0.05em 0.25em;
  transform: translateY(-50%);
  white-space: nowrap;
}

.success-dialog-title p {
  margin: unset;
}

.backdrop .vote-container-success {
  display: grid;
  gap: 36px;
  grid-template-columns: 1fr 1fr;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 48px;
  // box-sizing: border-box;
  max-height: 75vh;
}

.backdrop .vote-container-success .profile-picture-wrapper {
  aspect-ratio: 1;
  position: relative;
}

.backdrop .vote-container-success .profile-picture-wrapper .contestant_id {
  color: #b59473;
  font-family: Helvetica Neue, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3px;
  -webkit-text-stroke: 1px white;
  position: absolute;
  top: 0;
  right: 16px;
  transform: translateY(-50%);
  margin: unset;
}

.backdrop .vote-zone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.backdrop .vote-zone #last {
  // height: -webkit-fill-available;
  height: max-content;
  display: flex;
  gap: 1em;
}

.backdrop .vote-zone #last .vote-btn {
  align-self: flex-end;
  transition: all 0.2s ease-in-out;
}

.vote-btn.secondary {
  background: #fff;
  color: #dcac60;
  border: 1px solid #dcac60;
}

.vote-btn.secondary:hover {
  background: #dcac60;
  color: #fff;
}

.backdrop .vote-zone #last .vote-btn.disabled {
  filter: grayscale(1);
  opacity: 0.5;
  pointer-events: none;
}

.backdrop .vote-container-success .vote-chant {
  color: #1a1a1a;
  font-family: Noto Serif TC;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.9px;
  padding-bottom: 24px;
  border-bottom: 1px solid #dcac60;
  height: fit-content;
  margin-top: 0;
}

.backdrop .vote-container-success .vote-chant.confirmation {
  border-bottom: unset;
}

.backdrop .vote-container-success .vote-chant.confirmation .notes {
  font-size: 16px;
  opacity: 0.5;
}

.backdrop .vote-container-success .vote-chant .name_cn {
  color: #dcac60;
  font-family: Noto Sans TC, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 4.5px;
}

.backdrop .vote-container-success .vote-chant .name_en {
  color: #dcac60;
  font-family: Noto Sans TC, sans-serif;
  font-size: 28px;
  letter-spacing: 1.4px;
}

.profile-picture-wrapper.prev {
  animation: swipe-from-right 0.25s ease-in-out;
}

.profile-picture-wrapper.next {
  animation: swipe-from-left 0.25s ease-in-out;
}

.vote-btn-list {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.vote-btn-list .fix {
  aspect-ratio: 1;
}

.vote-btn-list input {
  height: 48px;
  width: fit-content;
  max-width: 128px;
  border: unset;
  color: #dcac60;
  text-align: center;
  font-family: Times New Roman;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 3.8px;
  text-indent: 3.8px;
  border-radius: 5px;
  background: linear-gradient(0deg, #f8f6f4 0%, #f8f6f4 100%), #efefef;
  margin: 0 8px;
}

.vote-btn-list button {
  border-radius: 80px;
  border: 1px solid #dcac60;
  background: #fff;
  color: #dcac60;
  text-align: center;
  font-family: Times New Roman;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2.6px;
  padding: 0 12px;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  cursor: pointer;
}

.vote-btn-list button:hover {
  background: #dcac60;
  color: #fff;
}

.vote-btn-list button:hover path {
  fill: white;
}

.vote-zone .notes {
  text-align: left;
  margin-top: 12px;
  align-self: flex-start;
}

.vote-zone .notes p {
  font-family: Noto Sans TC, sans-serif;
  margin: 4px 0;
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
}

.vote-zone .notes .insufficient {
  color: #f0423b;
  font-family: Noto Sans TC, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1.2px;
  transition: all 0.2s ease-in-out;
}

.vote-zone .notes .insufficient.disabled {
  opacity: 0;
  pointer-events: none;
}

.vote-zone .notes .insufficient .highlight {
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.vote-zone .notes .insufficient .highlight a {
  color: unset;
}

.vote-zone .notes .insufficient .highlight:hover {
  opacity: 0.6;
}

@keyframes bounce-left-right {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(10%);
  }

  50% {
    transform: translateX(0%);
  }

  75% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(0%);
  }
}

@media only screen and (max-width: 1024px) {
  .success-dialog-container .main .backdrop-edge .backdrop #content {
    flex-direction: column;
  }

  .success-dialog-container .main .backdrop-edge .backdrop #title #label {
    font-size: 48px;
  }

  .success-dialog-thanks-wrapper .vote {
    font-size: 16px;
  }

  .success-dialog-title {
    font-size: 32px;
  }

  .success-dialog-container {
    gap: 8px;
  }

  .success-dialog-container .arrow {
    height: 64px;
    width: 64px;
  }

  .success-dialog-container .main .backdrop-edge .backdrop .top-bar .close-btn {
    width: 36px;
    height: 36px;
  }

  .backdrop .vote-container-success .profile-picture-wrapper {
    aspect-ratio: unset;
  }

  .backdrop .vote-container-success {
    gap: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 36px;
    max-height: 50vh;
    grid-template-columns: 2fr 3fr;
  }

  .backdrop .vote-container-success .vote-chant {
    font-size: 20px;
  }

  .backdrop .vote-container-success .vote-chant.confirmation .notes {
    font-size: 14px;
  }

  .backdrop .vote-container-success .vote-chant .name_cn {
    font-size: 24px;
  }

  .backdrop .vote-container-success .vote-chant .name_en {
    font-size: 22px;
  }

  .vote-btn-list button {
    height: 48px;
    font-size: 20px;
    letter-spacing: 1px;
  }

  .vote-btn-list input {
    max-width: 96px;
    font-size: 28px;
    height: 32px;
  }

  .vote-zone .notes p {
    font-size: 16px;
  }

  .vote-zone .notes .insufficient {
    font-size: 18px;
  }
}

@media only screen and (max-width: 848px) {
  .success-dialog-name-cn {
    font-size: 24px;
  }

  .success-dialog-name-en {
    font-size: 20px;
  }

  .success-dialog-container {
    gap: 6px;
  }

  .success-dialog-container .arrow {
    height: 48px;
    width: 48px;
  }

  .backdrop .vote-container-success .profile-picture-wrapper {
    aspect-ratio: 0.75;
  }

  .backdrop .vote-container-success .vote-chant {
    font-size: 14px;
    padding-bottom: 12px;
  }

  .backdrop .vote-container-success .vote-chant.confirmation .notes {
    font-size: 12px;
  }

  .backdrop .vote-container-success .vote-chant .name_cn {
    font-size: 18px;
  }

  .backdrop .vote-container-success .vote-chant .name_en {
    font-size: 16px;
  }

  .vote-btn-list button {
    height: 32px;
    padding: 0 8px;
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  .vote-btn-list input {
    font-size: 16px;
    max-width: 81px;
    height: 32px;
    margin: unset;
  }

  .vote-zone .notes p {
    font-size: 14px;
  }

  .vote-zone .notes .insufficient {
    font-size: 16px;
  }
}

@media only screen and (max-width: 720px) {
  .success-dialog-container .main .backdrop-edge .backdrop #title #label {
    font-size: 24px;
    letter-spacing: 1px;
  }

  .success-dialog-container {
    width: 100%;
  }

  .backdrop .vote-container-success .profile-picture-wrapper {
    aspect-ratio: 1;
    width: 50vw;
  }

  .backdrop .vote-zone #last {
    margin-bottom: 16px;
  }

  .backdrop .vote-container-success {
    display: flex;
    gap: 16px;
    flex-direction: column;
    max-height: fit-content;
    justify-content: center;
    align-items: center;
  }

  .vote-btn-list button {
    padding: 8px 8px;
    letter-spacing: 0;
    height: 32px;
  }

  .vote-btn-list input {
    height: 32px;
  }

  .success-dialog-container {
    grid-template-columns: 36px 1fr 36px;
    gap: 4px;
  }

  .success-dialog-container .main {
    max-width: calc(100vw - 16px);
    max-height: calc(90vh);
  }

  .success-dialog-container .arrow {
    height: 36px;
    width: 36px;
  }

  // .success-dialog-container .left {
  //     transform: translateX(100%);
  //     z-index: 2;
  // }

  // .success-dialog-container .left circle {
  //     stroke: white;
  //     stroke-width: 2;
  // }

  // .success-dialog-container .right {
  //     transform: translateX(-100%);
  //     z-index: 2;
  // }

  // .success-dialog-container .right circle {
  //     stroke: white;
  //     stroke-width: 2;
  // }

  .vote-btn {
    font-size: 16px;
  }

  .backdrop .vote-container-success .vote-chant .name_cn {
    letter-spacing: 2px;
  }

  .backdrop .vote-container-success .vote-chant .name_en {
    letter-spacing: 0px;
  }
}

@keyframes swipe-from-left {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes swipe-from-right {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
