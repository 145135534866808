.live-vote-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.live-vote-logo {
  height: 100%;
  width: 70%;
  object-fit: contain;
}

.about-event {
  height: 100%;
  width: 100%;
}

.live-vote-header-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
    font-family: Noto Serif TC;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: 0.2em;
    text-align: center;
  }

  span {
    letter-spacing: 0em;
    padding-right: 0.2em;
  }
}

.live-vote-warning-message {
  font-family: Noto Sans TC;
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  color: #626262;
  width: 100%;
  max-width: 500px;
}

.live-vote-upper-section {
  padding: 3em 1em;
  display: flex;
  flex-direction: column;
  gap: 1.4em;
}

.live-vote-bottom-section {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 720px;
}

.bar-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.4em;
  width: 100%;
  box-sizing: border-box;
  max-width: 720px;
  padding: 0em 1em;
}

.banner-wrapper {
  display: flex;
  width: 100%;
  // max-width: 1280px;
  justify-content: center;
  align-items: center;
  position: relative;
  .banner-title-wrapper {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }
  .banner-title {
    -webkit-animation: glow 2s infinite;
    animation: glow 2s infinite;
    max-width: 60%;
    width: 100%;
  }
}

@keyframes glow {
  0% {
    -webkit-filter: brightness(1);
    filter: brightness(1);
  }
  50% {
    -webkit-filter: brightness(1.2);
    filter: brightness(1.2);
  }
  100% {
    -webkit-filter: brightness(1);
    filter: brightness(1);
  }
}
.live-vote-tab-wrapper {
  display: flex;
  width: 100%;
  max-width: 720px;
  padding: 0em 1em;
  box-sizing: border-box;
}

.live-vote-tab {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 2rem;
  border-radius: 12px;
  border: 2px solid #dcac60;
  :nth-child(1) {
    border-radius: 8px 0px 0px 8px;
  }

  :nth-child(2) {
    border-radius: 0px 8px 8px 0px;
  }

  .live-vote-tab-item {
    font-family: Noto Sans TC;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    background-color: #62626220;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
  }

  .live-vote-tab-item:not(.active):hover {
    background-color: #62626240;
  }

  .active {
    background-color: #dcac60;
  }
}

.home-page-pageant-auto-scroller-wrapper {
  position: fixed;
  z-index: 199;
  bottom: 32px;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  transition: all 0.25s ease-in-out;
}

.home-page-pageant-auto-scroller-wrapper.show {
  opacity: 1;
  pointer-events: all;
}

.home-page-pageant-auto-scroller-wrapper.show
  .home-page-pageant-auto-scroller-container
  a {
  pointer-events: all;
}

.home-page-pageant-auto-scroller-container {
  // opacity: 0;
  // pointer-events: none;
  transition: all 0.25s ease-in-out;
  // background: rgba(215, 119, 181, 0.5);
  color: white;
  border-radius: 16px;
  font-family: Noto Sans TC, sans-serif;
  font-weight: 600;
  padding: 4px 8px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 32px;
  // backdrop-filter: brightness(0.8);
}

.home-page-pageant-auto-scroller-container a {
  background: #9fd1ff;
  border-radius: 50%;
  aspect-ratio: 1;
  width: 32px;
  display: block;
  text-decoration: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-in-out;
  border: 2px solid #e5f0fa;
  color: #754163;
  user-select: none;
}

.home-page-pageant-auto-scroller-container a.active {
  background: #358fe1;
  color: white;
}

.home-page-pageant-auto-scroller-container a:hover {
  background: #358fe1;
  color: white;
}

.home-page-pageant-auto-scroller-container .bubble {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-page-pageant-auto-scroller-container .bubble::after {
  content: "";
  height: 4px;
  background: #e5f0fa;
  width: 4px;
}

.home-page-pageant-auto-scroller-container .bubble:last-child::after {
  content: "";
  height: unset;
  background: unset;
  width: unset;
}
