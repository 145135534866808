.contestant-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 36px;
}

.contestant-crown {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  position: relative;
}

.contestant-crown.other {
  transform: scale(0.85);
}

.contestant-crown.out-of-range {
  opacity: 0;
}

.contestant-crown.first path {
  fill: #ffd28d;
}

.contestant-crown.first span {
  color: #665946;
}

.contestant-crown.second path {
  fill: #c9c9c9;
}

.contestant-crown.second span {
  color: #767676;
}

.contestant-crown.third path {
  fill: #d3ba93;
}

.contestant-crown.third span {
  color: #83745b;
}

.contestant-crown.other path {
  fill: #e3e3e3;
}

.contestant-crown.other span {
  color: #888888;
}

.contestant-crown span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: "Helvetica Neue";
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
}

.contestant-vote-info {
  color: #333;
  font-family: Noto Sans TC, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 28.8px */
}

.contestant-vote-info .vote-count {
  color: #dcac60;
  font-family: Noto Sans TC, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  padding: 0 4px;
}

.contestant-profile-picture-display {
  width: 100%;
  position: relative;
  transition: all 0.25s ease-in-out;
}

.contestant-profile-picture-display img {
  aspect-ratio: 4/5;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
  border-radius: 20px;
}

.contestant-profile-picture-display .contestant-id {
  color: #b59473;
  font-family: Helvetica Neue;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3px;
  -webkit-text-stroke: 1px white;
  position: absolute;
  top: 0;
  right: 16px;
  transform: translateY(-50%);
}

.contestant-basic-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  background: white;
  border-radius: 0 20px 0px 0px;
  color: #1a1a1a;
  font-family: Noto Sans TC, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.9px;
  padding: 16px 24px;
  box-sizing: border-box;
  transition: all 0.25s ease-in-out;
}

.contestant-basic-details p {
  margin: unset;
}

.contestant-basic-details p span {
  font-size: 14px;
  letter-spacing: normal;
  opacity: 0.5;
}

.contestant-basic-details .url {
  cursor: pointer !important;
  text-decoration: none;
  user-select: none;
  font-size: 16px !important;
}

.contestant-basic-details .normal {
  color: #626262;
  font-family: Noto Sans TC, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  letter-spacing: 0;
  transition: all 0.25s ease-in-out;
  max-height: 36px;
}

.contestant-basic-details .normal.first {
  margin-top: 12px;
}

.contestant-basic-details .normal.hide {
  max-height: 0px;
  opacity: 0;
  pointer-events: none;
}

.contestant-basic-details .normal.bold {
  font-weight: 600;
}

.contestant-basic-details .name-en {
  color: #1a1a1a;
  font-family: Noto Sans TC, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-align: left;
}

.contestant-details-view-btn {
  background-color: #160100;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid #160100;
  transition: all 0.25s ease-in-out;

  &:hover {
    background-color: #ffffff;
  }
}

.contestant-details-view-btn:hover {
  background-color: white;
}

.contestant-details-view-btn:hover path {
  stroke: #dcac60;
}

.contestant-details-view-btn svg {
  animation: bounce-bl-tr 1s ease-in-out infinite;
  stroke: white;
  stroke-width: 1px;
}

.contestant-details-close-btn {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  svg {
    circle {
      stroke: #dcac60;
    }
    path {
      fill: #f8f6f4;
    }
  }
  &:hover {
    svg {
      circle {
        fill: #dcac60;
      }
      path {
        stroke: #f8f6f4;
      }
    }
  }
}

// .contestant-details-close-btn:hover {
//   background-color: #af0f77;
// }

// .contestant-details-close-btn:hover path {
//   stroke: #af0f77;
// }

.contestant-vote-btn {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.125));
  margin-top: 16px;
  border: 2px solid transparent;
  border-radius: 100px;
  background: linear-gradient(
    65deg,
    #e9c156 0%,
    #d6ab4a 14%,
    #e1ab39 31%,
    #e3b040 45%,
    #edcc66 53%,
    #f4e283 60%,
    #faf299 68%,
    #fdfba5 75%,
    #ffffaa 82%,
    #fdf9a3 85%,
    #f8eb8f 90%,
    #efd470 96%,
    #e9c156 100%
  );
  background-size: 200% 200%;
  animation: contestant-vote-btn-shine 5s ease-in-out infinite;
  color: #fff;
  text-align: center;
  font-family: Noto Serif TC;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  letter-spacing: 3.3px;
  padding: 4px 28px;
  align-self: center;
  cursor: pointer;
  background-position: 0% 0%;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    transform: translate(1px, 1px);
    background: linear-gradient(136.74deg, #dcac60 3.39%, #9e7c45 113.44%);
    border-radius: 100px;
    z-index: -1;
  }
}

.contestant-vote-btn:hover {
  transition: all 0.15s ease-in-out;
  border: 2px solid #dcac60;
}

.ig-icon {
  width: 24px !important;
  height: 24px !important;
}

a.ig {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 16px;
}

@keyframes contestant-vote-btn-shine {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 90% 90%;
  }

  100% {
    background-position: 0% 0%;
  }
}

@keyframes bounce-bl-tr {
  0% {
    transform: translate(-15%, 15%);
  }

  50% {
    transform: translate(15%, -15%);
  }

  100% {
    transform: translate(-15%, 15%);
  }
}

@media only screen and (max-width: 1024px) {
  .contestant-vote-btn {
    padding: 4px 12px;
  }
}
