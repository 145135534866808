.show-bar-chart-wrapper {
  display: flex !important;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background: #62626210;
  border-radius: 0.6rem;
  padding: 1.4rem 0rem;
}

.bar-chart-wrapper {
  display: none;
  pointer-events: none;
  .bar-chart-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    width: 100%;
    padding-bottom: 1.2rem;

    @media screen and (max-width: 512px) {
      font-size: 20px;
    }
  }
  .bar-chart-container {
    display: grid;
    grid-template-columns: 15% 1fr;
    padding: 0rem 1rem;
    width: 100%;
    box-sizing: border-box;

    @media screen and (max-width: 512px) {
      padding: 0rem 0.8rem;
      grid-template-columns: 18% 1fr;
    }

    .contestant-name-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      gap: 1rem;
      border-right: 1px solid #dcac60;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      // @media screen and (max-width: 512px) {
      //   display: none;
      // }

      .contestant-name {
        display: flex;
        align-items: center;
        height: 32px;
        p {
          margin: 0;
          font-size: 16px;
          // padding: 0px 8px;
          @media screen and (max-width: 512px) {
            font-size: 14px;
          }
        }
      }
    }

    .bar-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      gap: 1rem;
      @media screen and (max-width: 512px) {
        border-left: 1px solid #dcac60;
      }

      .bar {
        // border: 1px solid #1e1e1e;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background: #dcac60;
        border-top: 1px solid #1e1e1e40;
        border-right: 1px solid #1e1e1e40;
        border-bottom: 1px solid #1e1e1e40;
        width: 100%;
        height: 100%;
        min-height: 30px;
        max-height: 32px;
        max-width: 0px;
        position: relative;
        transition: all 0.3s ease-in-out;

        // @media screen and (max-width: 512px) {
        //   height: 42px;
        //   min-height: unset;
        //   max-height: unset;
        // }

        p {
          color: #ffffff;
          margin: 0;
          font-size: 12px;
          padding-right: 8px;
        }
      }

      .floating-text {
        position: absolute;
        right: -55px;
        color: #1e1e1e !important;
        font-weight: 600;
      }
    }

    .vote-interval {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 12px;
      border-top: 1px solid #dcac60;

      p {
        margin: 1em 0em 0em 0em;
      }
    }
  }
}
