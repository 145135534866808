#logo:hover {
  filter: brightness(1.1);
}

.home-page-parent {
  position: relative;
}

.home-page-description {
  font-family: Noto Sans TC, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 0.1em;
  text-align: center;
  max-width: 854px;
}

.home-page-description-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px 12px;
  box-sizing: border-box;
}

.home-page-top-bar {
  height: 72px;
  padding: 16px 48px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-page-top-bar #logo {
  max-height: 36px;
}

.home-page-top-bar .anchor-btn-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
}

.home-page-floating-top-bar {
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.25s ease-in-out;
  z-index: 49;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  background: white;
}

.home-page-floating-top-bar.show {
  opacity: 0.85;
  pointer-events: all;
}

.v-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1em;
  padding: 1em;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(64px);
  z-index: 99;
  transition: all 0.15s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.v-menu.show {
  opacity: 1;
  pointer-events: all;
}

.v-menu .anchor-btn {
  white-space: nowrap;
}

.burger-menu-icon {
  width: 36px;
  aspect-ratio: 1;
  cursor: pointer;
}

.side-menu {
  background: linear-gradient(167deg, #dcac60 0%, #8e3798 50%, #358fe1 100%);
  position: fixed;
  top: 0;
  right: 0;
  width: fit-content;
  height: 100%;
  z-index: 299;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  padding: 1em 2em;
  backdrop-filter: blur(4px);
  opacity: 0;
  pointer-events: none;
  transform: translateX(100%);
  transition: all 0.25s ease-in-out;
}

.side-menu .close-btn-wrapper {
  position: fixed;
  top: 18px;
  right: 18px;
}

.side-menu.show {
  opacity: 0.9;
  pointer-events: all;
  transform: translateX(0%);
}

.side-menu .anchor-btn {
  color: white;
}

.side-menu .burger-menu-icon {
  stroke: white;
}

.login-btn {
  background: linear-gradient(167deg, #dcac60 0%, #8e3798 50%, #358fe1 100%);
  border-radius: 12px;
  padding: 4px 16px;
  height: 32px;
  text-decoration: unset;
  color: white;
  font-weight: 600;
  transition: all 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-btn:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
}

.anchor-btn {
  text-decoration: unset;
  color: #1a1a1a;
  font-weight: 700;
  cursor: pointer;
}

.anchor-btn:hover {
  opacity: 0.8;
}

.home-page-top-bar #profile {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #f0f0f0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.home-page-top-bar #profile:hover {
  opacity: 0.8;
  box-shadow: 0 2px 8px 0px #00000020;
}

.home-page-top-bar .burger-menu {
  display: none;
}

.home-page-text-carousel {
  position: absolute;
  top: 0;
  left: 0;
}

.home-page-title {
  color: #1a1a1a;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-page-title p {
  margin: unset;
  z-index: 1;
}

.home-page-title.top {
  height: 206px;
  align-items: flex-end;
}

.home-page-title.second {
  padding: 1em 0px;
}

.home-page-title-contestants {
  color: #fff;
  text-align: center;
  font-family: Noto Serif TC;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3px;
  border-radius: 5px;
  background: linear-gradient(156deg, #d777b5 0%, #c883db 49.36%, #41b0d8 100%),
    #efefef;
  width: fit-content;
  padding: 0.05em 0.25em;
}

.home-page-title-contestants-description {
  color: #1a1a1a;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3px;
  border-radius: 5px;
  width: fit-content;
  padding: 0.05em 0.25em;
  opacity: 0.65;
}

.home-page-title-contestants.simplified {
  font-family: Noto Sans TC, sans-serif;
}

.home-page-title-contestants p {
  margin: unset;
}

.home-page-event-video-wrapper {
  background: #f8f6f4;
  border-radius: 48px;
  padding: 24px;
  padding-top: 0px;
  box-sizing: border-box;
}

.home-page-event-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  max-height: 720px;
  height: fit-content;
  aspect-ratio: 16/9;
}

.home-page-event-video iframe {
  border-radius: 8px;
  width: fit-content;
  height: 100%;
  aspect-ratio: 16/9;
}

.home-page-event-video .controls {
  width: 100%;
  max-width: 1280px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
  max-height: 720px;
}

.home-page-event-video .controls.playing {
  opacity: 0;
  pointer-events: none;
}

.home-page-event-video .controls svg {
  cursor: pointer;
}

.home-page-event-video .controls svg:hover {
  opacity: 0.8;
}

.home-page-event-video video {
  width: 100%;
  max-width: 1280px;
  border-radius: 20px;
  z-index: 1;
  cursor: pointer;
}

.home-page-event-video .backdrop {
  background: linear-gradient(167deg, #dcac60 0%, #8e3798 52.87%, #358fe1 100%);
  position: absolute;
  width: 100%;
  max-width: 1280px;
  border-radius: 20px;
  aspect-ratio: 16/9;
  transform: translate(20px, 20px);
  max-height: 720px;
}

.home-page-about-event-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 48px 0;
  padding: 0 24px;
  box-sizing: border-box;
}

.home-page-about-event-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  display: grid;
  gap: 64px;
  grid-template-columns: minmax(256px, 1fr) minmax(auto, 720px);
  padding: 0 24px;
}

.home-page-about-event-content table {
  height: fit-content;
}

.home-page-about-event-content tr {
  height: fit-content;
  width: fit-content;
}

.home-page-about-event-content .spacer {
  height: 16px;
}

.home-page-about-event-content td {
  width: fit-content;
  height: fit-content;
}

.home-page-about-event-content table .label {
  min-width: 160px;
  color: white;
  text-align: center;
  font-family: Noto Sans TC, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1.8px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.home-page-about-event-content table .label p {
  width: 100%;
  padding: 4px 24px;
  border-radius: 72px;
  margin: unset;
  background: linear-gradient(156deg, #d777b5 0%, #c883db 49.36%, #41b0d8 100%);
}

.home-page-about-event-content table .details {
  padding: 0px 24px;
  color: #333;
  font-family: Noto Sans TC, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  height: fit-content;
  text-align: justify;
}

.home-page-about-event-content table .details.address {
  text-align: left;
}

.home-page-about-event-content table .details p {
  margin: unset;
  line-height: 1.8;
}

.home-page-about-event-content table .details img {
  max-width: 128px;
}

.details .title {
  font-weight: 600;
}

.details .last {
  padding-bottom: 12px;
}

.home-page-contestants {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  margin: 48px 0;
  padding: 0 24px;
  box-sizing: border-box;
}

.home-page-contestant-list {
  margin-top: 48px;
  display: grid;
  width: 100%;
  max-width: 1792px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 32px;
}

.home-page-judges {
  background: #f1ecf3;
  border-radius: 90px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 64px;
}

.home-page-judge-list {
  display: grid;
  width: 100%;
  max-width: 1536px;
  justify-content: center;
  gap: 48px;
  grid-template-columns: repeat(auto-fit, minmax(256px, 320px));
  padding: 0 24px;
  box-sizing: border-box;
  margin: 24px 24px;
}

.home-page-judge-list > *:nth-child(even) {
  transform: translateY(48px);
}

.home-page-faq {
  background-image: url("/assets/images/full/faq-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
  background-attachment: fixed;
  padding: 0px 24px 48px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.home-page-faq-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 36px;
  max-width: 1280px;
  gap: 24px;
  background-color: rgba(255, 255, 255, 0.93);
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
}

.top-down-fader {
  height: 86px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff00 100%);
}

.home-page-footer {
  padding: 16px 48px;
  display: flex;
  height: 90px;
  justify-content: space-between;
  background: linear-gradient(190deg, #dcac60 0%, #8e3798 52.87%, #358fe1 100%);
}

.home-page-footer .left {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
}

.home-page-footer .left img {
  max-height: 48px;
}

.home-page-footer .left a {
  color: white;
}

.home-page-footer .right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
}

.home-page-footer .right a:hover {
  opacity: 0.8;
}

.home-page-contestant-fallback-wrapper {
  width: 100%;
  background-color: #e4e4e4;
  border-radius: 20px;
  aspect-ratio: 0.5;
  display: grid;
  grid-template-rows: 1fr 4fr 2fr 1fr;
  gap: 16px;
  padding: 16px;
  box-sizing: border-box;
  animation: opacity-bounce 1.5s infinite ease-in-out;
}

.home-page-contestant-fallback-wrapper .vote-count {
  border-radius: 12px;
  background-color: #f3f3f3;
  width: 100%;
  height: 100%;
}

.home-page-contestant-fallback-wrapper .details {
  border-radius: 12px;
  background-color: #f3f3f3;
  width: 100%;
  height: 100%;
}

.home-page-contestant-fallback-wrapper .profile-picture {
  background-color: #f3f3f3;
  width: 100%;
  height: -webkit-fill-available;
  border-radius: 12px;
}

.home-page-language-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 100;
}

.home-page-language-switch p {
  font-family: Noto Sans TC, sans-serif;
  font-weight: 700;
  color: white;
  cursor: pointer;
}

.home-page-language-switch.dark p {
  color: #dcac60;
}

.home-page-language-switch p:hover {
  opacity: 0.8;
}

.home-page-language-switch p.not-selected {
  opacity: 0.5;
}

.home-page-language-switch.dark p.not-selected {
  color: #1a1a1a;
  opacity: 0.5;
}

.home-page-progressing-wrapper {
  position: fixed;
  z-index: 299;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000000b0;
  // backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  color: #1a1a1a;
  font-family: Noto Serif TC;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.2px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.25s ease-in-out;
}

.home-page-progressing-container .close-btn {
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: 16px;
  right: 16px;
}

.home-page-progressing-container .close-btn svg {
  width: 100%;
  height: 100%;
}

.home-page-progressing-container .close-btn svg * {
  transition: all 0.2s ease-in-out;
}

.home-page-progressing-container .close-btn:hover svg circle {
  fill: #dcac60;
}

.home-page-progressing-container .close-btn:hover svg path {
  stroke: #f8f6f4;
}

.home-page-progressing-container {
  background: linear-gradient(167deg, #dcac60 0%, #8e3798 50%, #358fe1 100%);
  border-radius: 20px;
  padding: 4px;
  box-sizing: border-box;
  min-width: 320px;
  width: 100%;
  max-width: 512px;
}

.home-page-progressing-container .backdrop {
  background: white;
  border-radius: 16px;
  padding: 32px 24px;
  box-sizing: border-box;
  position: relative;
}

.home-page-progressing-container .backdrop .message {
  margin: 12px 0;
}

.home-page-progressing-container .backdrop .error {
  color: #626262;
  font-family: Noto Sans TC;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 28.8px */
  letter-spacing: 0.8px;
}

.home-page-progressing-wrapper.show {
  opacity: 1;
  pointer-events: all;
}

.home-page-pageant-auto-scroller-wrapper {
  position: fixed;
  z-index: 199;
  bottom: 32px;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  transition: all 0.25s ease-in-out;
}

.home-page-pageant-auto-scroller-wrapper.show {
  opacity: 1;
  pointer-events: all;
}

.home-page-pageant-auto-scroller-wrapper.show
  .home-page-pageant-auto-scroller-container
  a {
  pointer-events: all;
}

.home-page-pageant-auto-scroller-container {
  // opacity: 0;
  // pointer-events: none;
  transition: all 0.25s ease-in-out;
  // background: rgba(215, 119, 181, 0.5);
  color: white;
  border-radius: 16px;
  font-family: Noto Sans TC, sans-serif;
  font-weight: 600;
  padding: 4px 8px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 32px;
  height: fit-content;
  // backdrop-filter: brightness(0.8);
}

.home-page-pageant-auto-scroller-container a {
  background: linear-gradient(136.74deg, #dcac60 3.39%, #9e7c45 113.44%);
  border-radius: 45%;
  aspect-ratio: 1;
  width: 32px;
  display: block;
  text-decoration: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-in-out;
  border-width: 2px;
  color: #626262;
  user-select: none;
  position: relative;
  box-shadow: 3px 6px 16px rgba(120, 76, 7, 0.15);
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 0;
    left: 0;
    border-radius: 45%;
    transform: translate(1px, 1px);
    background: white;
    z-index: 0;
  }
}

.home-page-pageant-auto-scroller-container a.active {
  background: linear-gradient(
    156deg,
    #e9c156 0%,
    #d6ab4a 14%,
    #e1ab39 31%,
    #e3b040 45%,
    #edcc66 53%,
    #f4e283 60%,
    #faf299 68%,
    #fdfba5 75%,
    #ffffaa 82%,
    #fdf9a3 85%,
    #f8eb8f 90%,
    #efd470 96%,
    #e9c156 100%
  );
  color: white;

  &::before {
    background: linear-gradient(136.74deg, #dcac60 3.39%, #9e7c45 113.44%);
  }
}

.home-page-pageant-auto-scroller-container a:hover {
  background: linear-gradient(
    156deg,
    #e9c156 0%,
    #d6ab4a 14%,
    #e1ab39 31%,
    #e3b040 45%,
    #edcc66 53%,
    #f4e283 60%,
    #faf299 68%,
    #fdfba5 75%,
    #ffffaa 82%,
    #fdf9a3 85%,
    #f8eb8f 90%,
    #efd470 96%,
    #e9c156 100%
  );
  color: white;

  &::before {
    background: linear-gradient(136.74deg, #dcac60 3.39%, #9e7c45 113.44%);
  }
}

.home-page-pageant-auto-scroller-container .bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.home-page-pageant-auto-scroller-container .bubble .number {
  z-index: 2;
  line-height: 1em;
  font-family: Noto Sans;
  font-size: 12;
  transform: scale(0.8);
}

.home-page-pageant-auto-scroller-container .bubble::after {
  content: "";
  height: 1px;
  background: linear-gradient(136.74deg, #dcac60 3.39%, #9e7c45 113.44%);
  width: 6px;
}

.home-page-pageant-auto-scroller-container .bubble:last-child::after {
  content: "";
  height: unset;
  background: unset;
  width: unset;
}

.home-page-pageant-current-top-wrapper {
  position: fixed;
  z-index: 199;
  bottom: 96px;
  right: 0;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  pointer-events: none;
}

.home-page-pageant-current-top-wrapper.hide * {
  pointer-events: none !important;
}

.home-page-pageant-current-top-wrapper .leaderboard {
  opacity: 0;
  pointer-events: none;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  transform: translateX(50%);
}

.home-page-pageant-current-top-wrapper .leaderboard-btn {
  position: absolute;
  background: linear-gradient(156deg, #d777b5 0%, #c883db 49.36%, #41b0d8 100%),
    #efefef;
  color: white;
  padding: 32px 8px;
  border-radius: 32px 0px 0px 32px;
  width: min-content;
  white-space: pre-wrap;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  font-weight: 700;
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.home-page-pageant-current-top-wrapper .leaderboard.show {
  opacity: 1;
  pointer-events: none;
  transform: translateX(0%);
}

.home-page-pageant-current-top-wrapper .leaderboard.show a {
  pointer-events: auto;
}

.home-page-pageant-current-top-wrapper .leaderboard a {
  text-decoration: none;
}

.home-page-pageant-current-top-wrapper .leaderboard-btn.show {
  opacity: 1;
  pointer-events: all;
}

.home-page-pageant-current-top-wrapper .leaderboard-btn:hover {
  padding: 32px 12px;
}

.home-page-pageant-current-top-wrapper .scorer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  padding-left: 16px;
  border-radius: 64px 0px 0px 64px;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  width: min-content;
}

.home-page-pageant-current-top-wrapper .scorer.rank-1 {
  background: #ffd28d60;
}

.home-page-pageant-current-top-wrapper .scorer.rank-2 {
  background: #c9c9c960;
}

.home-page-pageant-current-top-wrapper .scorer.rank-3 {
  background: #d3ba9360;
}

.home-page-pageant-current-top-wrapper .scorer.rank-other {
  background: #e3e3e360;
}

.home-page-pageant-current-top-wrapper .scorer .profile-picture {
  width: 64px;
  height: 36px;
  border-radius: 64px;
  background: linear-gradient(
      156deg,
      #d777b580 0%,
      #c883db80 49.36%,
      #41b0d880 100%
    ),
    #efefef;
  transition: all 0.25s ease-in-out;
}

.home-page-pageant-current-top-wrapper .scorer.rank-2 .profile-picture {
  width: 56px;
}

.home-page-pageant-current-top-wrapper .scorer.rank-3 .profile-picture {
  width: 48px;
}

.home-page-pageant-current-top-wrapper .scorer.rank-other .profile-picture {
  width: 44px;
}

.home-page-pageant-current-top-wrapper .scorer:hover .profile-picture {
  width: 84px;
  height: 64px;
  background: linear-gradient(156deg, #d777b5 0%, #c883db 49.36%, #41b0d8 100%),
    #efefef;
}

.home-page-pageant-current-top-wrapper .scorer .profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 64px;
  transform-origin: top;
}

.home-page-pageant-current-top-wrapper .scorer p {
  text-decoration: none;
  color: #dcac60;
  font-weight: 700;
  white-space: nowrap;
  font-size: 18px;
  margin: unset;
}

.home-page-pageant-current-top-wrapper.show {
  opacity: 1;
  // pointer-events: all;
}

.home-page-pageant-current-top-wrapper.show
  .home-page-pageant-current-top-container
  a {
  pointer-events: all;
}

.leaderboard-close-btn {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  pointer-events: none;
}

.leaderboard-close-btn.show {
  pointer-events: auto;
}

.leaderboard-close-btn svg {
  width: 48px;
  height: 48px;
}

.plain path {
  fill: white !important;
}

@keyframes opacity-bounce {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

@media only screen and (max-width: 1280px) {
  .home-page-judge-list {
    grid-template-columns: repeat(auto-fit, minmax(320px, 480px));
  }

  .home-page-judge-list > *:nth-child(even) {
    transform: translateY(50%);
  }
}

@media only screen and (max-width: 1059px) {
  .home-page-judge-list > *:nth-child(even) {
    transform: unset;
  }
}

@media only screen and (max-width: 1024px) {
  .home-page-about-event-content table .details {
    text-align: center !important;
  }
}

@media only screen and (max-width: 896px) {
  .home-page-footer .right {
    gap: 1em;
  }

  .home-page-top-bar {
    padding: 16px 16px;
  }

  .home-page-top-bar .anchor-btn-list {
    display: none;
  }

  .home-page-top-bar #profile {
    width: 36px;
    height: 36px;
  }

  .home-page-top-bar .burger-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  .home-page-title {
    font-size: 28px;
  }

  .home-page-title.top {
    height: 128px;
  }

  .home-page-event-video-wrapper {
    border-radius: 16px;
  }

  .home-page-event-video video {
    border-radius: 12px;
  }

  .home-page-event-video .backdrop {
    border-radius: 12px;
    transform: translate(12px, 12px);
  }

  .home-page-event-video .controls {
    border-radius: 12px;
  }

  .home-page-event-video .controls svg {
    width: 64px;
    height: 64px;
  }

  .home-page-about-event-content {
    flex-direction: column;
    display: flex;
    grid-template-columns: unset;
  }

  .home-page-about-event-wrapper {
    padding: 0 0px;
  }

  .home-page-about-event-content table .label {
    width: fit-content;
    font-size: 16px;
    min-width: 128px;
    margin: 0 auto;
  }

  .home-page-about-event-content table .label p {
    padding: 4px 16px;
  }

  .home-page-about-event-content table .details {
    padding: 0px 16px;
    letter-spacing: 0px;
  }

  .home-page-title-contestants {
    font-size: 32px;
  }

  .home-page-title-contestants-description {
    font-size: 16px;
  }

  .home-page-judge-list {
    grid-template-columns: repeat(auto-fit, minmax(320px, 480px));
  }

  .home-page-judge-list > *:nth-child(even) {
    transform: unset;
  }

  .home-page-judges {
    background: #f1ecf3;
    border-radius: 32px;
  }

  .home-page-faq {
    padding: 24px 12px;
  }

  .home-page-faq-wrapper {
    padding: 24px 18px;
  }

  .home-page-footer {
    padding: 16px 16px;
  }

  .home-page-footer .left .anchor-btn {
    display: none;
  }
}
