@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&display=swap');

.dialog-flexbox {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.dialog-container {
    border-radius: 28px;
    background: white;
    padding: 2em;
    min-width: 30vw;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.dialog-title {
    color: #1C1B1F;
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding: 0.5em;
}

.dialog-description {
    color:  #1C1B1F;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding: 1em;
    text-align: start;
}

.dialog-button {
    color: #6750A4;
    cursor: pointer;
}

.dialog-parent {
    height: 100vh;
    width: 100%;
    background: #000000BF;
    position: absolute;
    top: 0
}

.dialog-buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 2em;
    padding-top: 1em;
    box-sizing: border-box;

}