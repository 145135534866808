@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@300;400;500;600;700&display=swap");
body {
  margin: 0;
  font-family: "Noto Serif TC", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sc {
  font-family: "Noto Serif SC", sans-serif !important;
  /* -webkit-transform: translate3d(0, 0, 0) */
}
.sans-sc {
  font-family: "Noto Sans TC", sans-serif !important;
  /* -webkit-transform: translate3d(0, 0, 0) */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  /* overflow: hidden; */
  height: 100%;
}

body {
  height: 100%;
  /* overflow: auto; */
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.rel {
  position: relative;
}

.rws-icon:hover {
  opacity: 0.8;
}

.rws-header {
  font-weight: 700 !important;
  letter-spacing: 2px;
}

.rws-close:hover {
  color: #edf2f7 !important;
  background: #1a1a1a !important;
}

.no-btn-style {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

.tone-up {
  /* filter: brightness(1.1) contrast(0.95); */
}
.hover-zoom {
  transition: all 0.3s ease-in-out;
}
.hover-zoom:hover {
  transform: scale(1.1);
}

a.nav {
  pointer-events: all;
  cursor: pointer;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

button,
a {
  user-select: none;
}
