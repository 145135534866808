.voting-selection-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.2px solid #9c9c9c;
  border-radius: 8px;
  transition: all 0.15s ease-in-out;
  width: 100%;
  max-width: 255px;
  padding: 0.4rem 0.4rem;
  box-sizing: border-box;
  font-size: 0.8rem;
  cursor: pointer;
  //   background: #ffffff;
  &:hover {
    border: 1.2px solid #dcac60;
    background: #dcac6050;
  }
}

.selected {
  color: #ffffff;
  background: #dcac60;
  border: 1.2px solid #dcac60;
  transition: all 0.15s ease-in-out;
}
