.fade-load-image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  mask-size: 100% 100%;
  mask-position: center;
  mask-repeat: no-repeat;
  min-width: 300px;
  //   max-height: 400px;

  @media screen and (max-width: 768px) {
    min-width: 224px;
  }

  @media screen and (max-width: 512px) {
    min-width: 196px;
  }
  @media screen and (max-width: 430px) {
    min-width: 148px;
  }
}

.fade-load-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.5s ease-in-out;
}

.fade-load-image-wrapper .preload {
  position: absolute;
  top: 0;
  left: 0;
  // filter: blur(2px);
  pointer-events: none;
}
