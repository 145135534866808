.dialog-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(4px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  box-sizing: border-box;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-in-out;
  background: #000000b0;

  .dialog-background {
    width: 100%;
    max-width: 60vw;
    height: fit-content;
    // max-height: 30vh;
    background: linear-gradient(167deg, #dcac60, #9e7c45);
    border-radius: 28px;
    padding: 0.2em;
    box-sizing: border-box;

    @media screen and (max-width: 512px) {
      max-width: 80vw;
    }

    .dialog-main-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background-color: #ffffff;
      border-radius: 28px;
      position: relative;
      padding: 2em 1em 3em 1em;

      .top-bar {
        padding: 16px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 0;
      }

      .close-btn {
        width: 60px;
        height: 60px;
        cursor: pointer;
        z-index: 2;

        @media screen and (max-width: 768px) {
          width: 48px;
          height: 48px;
        }

        @media screen and (max-width: 480px) {
          width: 32px;
          height: 32px;
        }

        svg {
          width: 100%;
          height: 100%;
          * {
            transition: all 0.2s ease-in-out;
          }
          circle {
            stroke: #dcac60;
          }
          path {
            fill: #f8f6f4;
          }
        }

        &:hover {
          svg {
            circle {
              fill: #dcac60;
            }
            path {
              stroke: #f8f6f4;
            }
          }
        }
      }

      .dialog-title {
        // display: flex;
        // justify-content: center;
        // align-items: flex-end;
        // width: 100%;
        padding-bottom: 1em;
        p {
          color: #dcac60;
          font-family: Noto Sans TC;
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0;
          @media screen and (max-width: 768px) {
            font-size: 26px;
          }
        }

        .zh {
          letter-spacing: 4.5px;
        }

        .en {
          letter-spacing: unset;
        }
      }

      .dialog-body {
        p {
          color: #1e1e1e;
          font-family: Noto Sans TC;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 2em;
          margin: 0;
          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
        .zh {
          letter-spacing: 2px;
        }

        .en {
          letter-spacing: unset;
        }
      }
    }
  }
}

.dialog-wrapper.enable {
  opacity: 1;
  pointer-events: all;
}
